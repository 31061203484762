@import 'normalize/import-now';

/* GLOBAL STYLES */
///////////////////
* {
    box-sizing: border-box;
}

:first-child {
    margin-top: 0;
}

:last-child {
    margin-bottom: 0;
}


/* GLOBAL IMPORTS */
////////////////////

@import 'variables';

$color-text-welsh: #005587;
$color-footer: #005587;
$color-primary: #1CA4CC;
$color-secondary: #1CA4CC;
$color-tertiary: #1CA4CC;
$color-action: #B45B5B;

@import 'typography';
@import 'accessibility';
@import 'utility';

@import 'mixins/mixins';

@import 'elements/elements';
@import 'forms/forms';
@import 'blocks/blocks';
@import 'apps/apps';
@import 'layout/layout';


// Admin specific styles
#glitter-versionlist { border-collapse: collapse; }

#glitter-menu ~ .page__holder {
    max-height: none;
    background: $color-footer;
}
